import ReactCookies from 'react-cookies';
import axios from '../../components/_generic/axiosSplunk/axiosSplunk';
import { CHECKOUT } from '../../config/constants/action-types';
import COOKIES from '../../config/cookies/cookies';
import { LINK_CHECKOUT } from '../../config/links/links';
import { BRAND_WOWCHER, URLCHECKOUT } from '../../config/setup/setup';
import { trackEvent, updateDod } from '../../helpers/analytics';
import { getAppPlatform } from '../../helpers/device';
import { getFiveMinInFuture } from '../../helpers/timer';
import { getIsEligibleForVip } from '../../helpers/vipSubscribeHelper';
import { CHECKOUT_STATUS } from '../reducers/checkout';
import { createBasket, removeExcessGift } from './basket';
import { setBasketTotal } from './user';

export const setCheckoutStatus = (status) => (dispatch) => {
  dispatch({
    status,
    type: CHECKOUT.SET_CHECKOUT_STATUS,
  });
};

export const setIsTravel = (isTravel) => (dispatch) => {
  dispatch({
    isTravel,
    type: CHECKOUT.SET_TRAVEL,
  });
};

export const setIsTravelValid = (isTravelValid) => (dispatch) => {
  dispatch({
    isTravelValid,
    type: CHECKOUT.SET_TRAVEL_VALID,
  });
};

export const setCountdown = () => (dispatch) => {
  const countdown = getFiveMinInFuture(); // 5 minutes in milliseconds
  dispatch({
    countdown,
    type: CHECKOUT.SET_COUNTDOWN,
  });
};

export const resetCountdown = (dispatch) => {
  dispatch({ type: CHECKOUT.RESET_COUNTDOWN });
};

// Keep track of query params across checkout api calls
export const updateCheckoutApiParams = (queryParameters) => (dispatch) => {
  dispatch({
    queryParams: queryParameters,
    type: CHECKOUT.CHECKOUT_API_PARAMS,
  });
};

function getGiftWrapPreset(giftingDetails) {
  return {
    dealId: giftingDetails ? giftingDetails.giftWrap.dealId : 16_172_264,
    gift: false,
    giftPack: false,
    giftWrap: true,
    id: giftingDetails ? giftingDetails.giftWrap.productId : 2_440_914,
    // always start with zero for new gift packs so we know when a new pack has to be created
    payDeposit: false,
    quantity: 0,
  };
}

export const getCheckoutData = async (
  token,
  queryParameters,
  isReservationEnd,
) => {
  if (!token) {
    throw new Error('No token provided');
  }
  const purchaseSource = ReactCookies.load(COOKIES.sessionSource) || '';
  queryParameters.timestamp = Date.now();
  if (isReservationEnd) {
    queryParameters.timerElapsed = 'true';
  }
  const headers = {
    'access-control-allow-credentials': true,
    ALT_PAYMENT_METHODS: 'GOOGLE_PAY,APPLE_PAY',
    'App-Platform': getAppPlatform(),
    Brand: process.env.NEXT_PUBLIC_BRAND || BRAND_WOWCHER,
    'country-code': process.env.NEXT_PUBLIC_COUNTRY_CODE || 'gb',
    'Purchase-Source': purchaseSource,
    version: 'v1.1',
    vipVersion: 'v1.1',
    webapp: true,
  };

  headers.gpkVersion = 'v1.1';

  const resp = await axios({
    headers,
    method: 'GET',
    params: queryParameters,
    url: `${URLCHECKOUT}/${token}`,
    withCredentials: true,
  });
  const data = resp.data;

  return {
    checkoutData: data,
    giftingConfig: data?.giftingDetails || {},
    giftWrapPreset: getGiftWrapPreset(data.giftingDetails),
    type: CHECKOUT.SET_CHECKOUT,
  };
};

/** Load the checkout data (triggered on page load) */
export const loadCheckoutData = (
  token,
  userVipStatus,
  isAuthenticated,
) => async (dispatch, getState) => {
  try {
    dispatch(setCheckoutStatus(CHECKOUT_STATUS.LOADING));
    let checkoutData = await getCheckoutData(
      token,
      getState().checkout.queryParams,
    );

    if (
      checkoutData &&
      checkoutData.checkoutData &&
      checkoutData.checkoutData.deals &&
      checkoutData.checkoutData.deals.length > 0
    ) {
      // backend now checks for any spiked or sold out
      // check for any orphan giftPacks or giftWraps
      const giftPackOrWrapWasRemoved = await dispatch(
        removeExcessGift(token, checkoutData.checkoutData.deals),
      );
      if (giftPackOrWrapWasRemoved) {
        // need to get checkout data again
        checkoutData = await getCheckoutData(
          token,
          getState().checkout.queryParams,
        );
      }
    }
    dispatch(checkoutData);
    dispatch(setCheckoutStatus(CHECKOUT_STATUS.READY));
    dispatch(setBasketTotal(checkoutData?.checkoutData?.orderSummary || {}));
    updateDod({
      totalBasketValue:
        checkoutData?.checkoutData?.orderSummary?.totalOrderAmount || 0,
    });
  } catch (error) {
    // TODO: show an error state - the basket couldn't be loaded
    // If status code is 422 then create a new basket
    if (error?.response?.status === 422) {
      const customerToken = ReactCookies.load(COOKIES.customerToken);
      const isVipEligible = getIsEligibleForVip(isAuthenticated, userVipStatus);
      try {
        const response = await createBasket([], isVipEligible, customerToken);
        window.location.replace(`${LINK_CHECKOUT}/${response}`);
      } catch {
        console.log('error creating a basket a new basket');
      }
    }
    console.warn('gifting error');
    dispatch(setBasketTotal({}));
    updateDod({
      totalBasketValue: 0,
    });
  }
};

/** Refresh the checkout data (triggered by the countdown) */
export const refreshCheckoutData = (token, ssrTry = null) => async (
  dispatch,
  getState,
) => {
  try {
    dispatch(setCheckoutStatus(CHECKOUT_STATUS.REFRESHING));
    if (!ssrTry) {
      const checkoutData = await getCheckoutData(
        token,
        getState().checkout.queryParams,
      );
      dispatch(checkoutData);
    }
    dispatch(setCheckoutStatus(CHECKOUT_STATUS.REFRESHED));
    // 'prices confirmed' shows for 4 seconds before restarting countdown and hiding the message
    setTimeout(() => {
      dispatch(resetCountdown);
      dispatch(setCheckoutStatus(CHECKOUT_STATUS.READY));
    }, 4_000);
  } catch {
    dispatch(resetCountdown);
    dispatch(setCheckoutStatus(CHECKOUT_STATUS.READY));
  }
};

/** refresh checkout data after timer */
export const refreshReservationEndsCheckoutData = (token) => async (
  dispatch,
  getState,
) => {
  try {
    dispatch(setCheckoutStatus(CHECKOUT_STATUS.UPDATING));
    const checkoutData = await getCheckoutData(
      token,
      getState().checkout.queryParams,
      true,
    );
    dispatch(checkoutData);
    dispatch(setCheckoutStatus(CHECKOUT_STATUS.READY));
  } catch {
    // TODO: do we want to show some form of failure message here?
  }
};

const trackPromoCodeEvents = (promoCode) => {
  if (promoCode.applicable) {
    trackEvent('promo_code_successful');
  }
  if (!promoCode.applicable) {
    if (promoCode.message.includes('expired')) {
      trackEvent('promo_code_expired');
    } else if (promoCode.message.includes('invalid')) {
      trackEvent('promo_code_invalid');
    } else if (promoCode.message.includes('already used')) {
      trackEvent('promo_code_already_used');
    } else if (promoCode.message.includes('not applicable')) {
      trackEvent('promo_code_na_deal');
    } else if (promoCode.message.includes('not eligible')) {
      trackEvent('promo_code_na_user');
    }
  }
};
/** Update checkout data */
export const updateCheckoutData = (token) => async (dispatch, getState) => {
  try {
    dispatch(setCheckoutStatus(CHECKOUT_STATUS.UPDATING));
    const checkoutData = await getCheckoutData(
      token,
      getState().checkout.queryParams,
    );
    trackPromoCodeEvents(checkoutData.checkoutData.promoCode);
    dispatch(checkoutData);
    dispatch(setCheckoutStatus(CHECKOUT_STATUS.READY));
  } catch {
    // TODO: do we want to show some form of failure message here?
  }
};
